module.exports = angular
    .module('backstage.components', [
        require('./common/users-event-picker/users-event-picker.component'),
        require('./common/server-selection/server-selection.component'),
        require('./common/language-picker/language-picker.component'),
        require('./common/multilanguage-picker/multilanguage-picker.component'),
        require('./common/legal-docs-picker/legal-docs-picker.component'),
        require('./common/call-to-actions/call-to-actions.component'),

        require('./orgs/all-orgs-list/all-orgs-list.component'),
        require('./orgs/view-org/org-member-list/org-member-list.component'),
        require('./orgs/view-org/org-event-list/org-event-list.component'),
        require('./orgs/view-org/org-content-hub-list/org-content-hub-list.component'),
        require('./orgs/view-org/org-legal-docs-list/org-legal-docs-list.component'),
        require('./orgs/view-org/org-entitlements-manager/org-entitlements-manager.component'),
        require('./orgs/view-org/org-entitlements-manager/org-entitlements/org-entitlements.component'),
        require('./orgs/view-org/org-entitlements-manager/org-entitlement-transactions/org-entitlement-transactions.component'),
        require('./orgs/view-org/org-entitlements-manager/org-event-entitlements/org-event-entitlements.component'),
        require('./orgs/view-org/org-entitlements-manager/org-per-event-entitlements/org-per-event-entitlements.component'),
        require('./orgs/view-org/org-entitlements-manager/org-entitlement-product-block/org-entitlement-product-block.component'),
        require('./orgs/view-org/org-entitlements-manager/org-assign-entitlement/org-assign-entitlement.component'),
        require('./orgs/view-org/org-entitlements-manager/org-product-mappings/org-product-mappings.component'),
        require('./orgs/view-org/org-entitlements-manager/org-entitlements-table/org-entitlements-table.component'),
        require('./orgs/view-org/org-entitlements-manager/org-entitlement-details/org-entitlement-details.component'),
        require('./orgs/view-org/org-entitlements-manager/org-entitlement-overage-report/org-entitlement-overage-report.component'),
        require('./orgs/view-org/org-entitlements-manager/org-entitlements-exporter/org-entitlements-exporter.component'),
        require('./orgs/view-org/org-package-manager/org-package-manager.component'),

        require('./events/create-event-button/create-event-button.component'),

        require('./events/copy/copy-report/copy-report.component'),

        require('./file-management/batch-file-management/batch-file-management.component'),
        require('./legal-documents/legal-doc-editor/legal-doc-editor.component'),

        require('./login/login-form/login-form.component'),

        require('./branded-app/branded-app-activation-action-editor/branded-app-activation-action-editor.component'),
        require('./branded-app/branded-app-changelog/branded-app-changelog.component'),
        require('./branded-app/branded-app-email-status/branded-app-email-status.component'),
        require('./branded-app/branded-app-filters/branded-app-filters.component'),
        require('./branded-app/branded-app-publishing-request-card/branded-app-publishing-request-card.component'),
        require('./branded-app/branded-app-sidebar/branded-app-sidebar.component'),
        require('./branded-app/branded-app-welcome-message/branded-app-welcome-message.component')
    ])
    .value('EventEmitter', payload => ({ $event: payload })).name;
