// Utils
import { clone, get, groupBy, isEmpty, isNil, isObject, uniqueId } from 'lodash';
import { humanize } from 'libs/utils/string';

// Constants
import { DEFAULT_DATETIME_FORMAT } from 'libs/utils/constants';

const GENERATED_DEFAULT_VALUE_PLACEHOLDER = '{{bstg:random}}';

/**
 * @typedef {Object} ControlConfig
 * @property {boolean} noTip whether to show the tooltip
 * @property {boolean} noLabel whether to show the label
 * @property {object} $i18n the i18n framework
 * @property {*} value the current control value
 * @property {string} [context] the context of the control
 * @property {object} [event] the workspace's object
 * @property {object} [parentModel] an optional model to pass to the control
 * @property {object} [settings] the settings of the workspace
 */

/**
 * @typedef {Object} ParsedServerError
 * @property {string} detail the error message
 * @property {string} [field] the field that caused the error
 * @property {string} [idField] the field that identifies the ID of the object
 * @property {string} [id] the id of the object that caused the error
 */

/**
 * Sorts the given fields by orders
 *
 * @param {FieldDescriptor[]} fields the fields to sort
 * @param {string} defaultKey the default category
 * @param {boolean} editing if we're in edition mode
 *
 * @returns {Record<string,FieldDescriptor[]>} the grouped and sorted matrix
 */
export function sortAndGroupFields(fields, defaultKey, editing = false) {
    if (!editing) {
        fields = fields.filter(f => !f.hidden);
    }

    let i = Math.max(...fields.filter(f => f.order).map(f => f.order));
    fields.forEach(f => f.order = Number.isFinite(f.order) ? f.order : Number.parseFloat(f.order || ++i));
    fields.sort((a, b) => a.order - b.order);

    const categories = groupBy(fields, c => {
        if (c.no_category) return uniqueId('_no_category_');
        return c.category || defaultKey;
    });

    const sortedCat = Object.keys(categories)
        // Sort by field order
        .sort((a, b) => {
            const a1 = categories[a][0].hasOwnProperty('order') ? categories[a][0].order : Infinity;
            const b1 = categories[b][0].hasOwnProperty('order') ? categories[b][0].order : Infinity;

            return a1 - b1;
        });

    const fieldsets = {};
    for (const cat of sortedCat) {
        const groups = groupBy(categories[cat], g => g.group);
        delete groups.undefined;

        for (const [groupName, groupFields] of Object.entries(groups)) {
            const replacementIndex = categories[cat].findIndex(f => f.field === groupFields[0].field && f.group === groupName);
            if (replacementIndex) {
                categories[cat][replacementIndex] = groups[groupName];
            }
        }

        categories[cat] = categories[cat].filter(f => !f.group);
        fieldsets[cat] = categories[cat];
    }

    return fieldsets;
}

/**
 * Filters fields by the given placement
 *
 * @param {FieldDescriptor[]} fields a list of field descriptors
 * @param {string} placement the position by which filtering the fields with
 *
 * @return {object[]} a new list of fields filtered by placement
 */
export function fieldsByPlacement(fields = [], placement = 'left') {
    return fields.filter(field => getFieldPlacement(field) === placement);
}

/**
 * Given a field descriptor this method determines the place of the input in the form
 *
 * @param {object} field the field descriptor
 * @param {'right'|'left'|undefined} [field.placement] the side field placement in the form
 * @param {string} field.kind the type of the field
 *
 * @return {'right'|'left'|string} the field placement
 */
export function getFieldPlacement(field) {
    if (field.placement) {
        return field.placement;
    } else if (['external', 'targets-exceptions'].includes(field.kind)) {
        return 'right';
    } else {
        return 'left';
    }
}

/**
 * Builds the attributes for a control
 *
 * @param {FieldDescriptor} control the control to build the attributes for
 * @param {ControlConfig} config the control's configuration
 * @param {FieldDescriptor} [overwrites = {}] extra attributes to add to the control
 *
 * @returns {object} the attributes for the control
 */
export function getControlAttributes(control, config, overwrites = {}) {
    // Apply defaults
    const attrs = {
        name: control.field,
        disabled: control.disabled,
        placeholder: control.placeholder || control.example,
        hasDefault: control.hasOwnProperty('default'),
        legacy: control,
        hint: config.$i18n.te(control.hint) ? config.$i18n.t(control.hint) : control.hint,
        hintAfterLabel: config.hintAfterLabel
    };

    if (!config.noTip) {
        attrs.tip = config.$i18n.te(control.tooltip) ? config.$i18n.t(control.tooltip) : control.tooltip;
    }

    if (!config.noLabel) {
        attrs.label = control.label || humanize(control.field, true);
        attrs.label = config.$i18n.te(attrs.label) ? config.$i18n.t(attrs.label) : attrs.label;

        if (get(control, 'validations.required')) {
            attrs.label = `${attrs.label} *`;
        }
    }

    // Decoration
    decorateWithKindSpecificAttributes(control, config, attrs);
    decorateWithControlOptionsAttributes(control, config, attrs);

    if (control.depends_on) {
        for (const field of Object.keys(control.depends_on)) {
            attrs.dependant = control.depends_on[field]?.includes('indent');
            break;
        }
    }

    if (control.validations?.maxlength) {
        attrs.maxlength = control.validations.maxlength;
    }

    applyOwerwrites(control, overwrites, attrs);

    // Cleanup
    removeNilValues(attrs);
    cleanUpAttributes(attrs);

    return attrs;
}

/**
 * Decorates the control with kind-specific attributes based on the control's kind.
 *
 * @param {FieldDescriptor} control the control object.
 * @param {ControlConfig} config the configuration object.
 * @param {object} attrs the attributes object to be decorated.
 */
function decorateWithKindSpecificAttributes(control, config, attrs) {
    if (control.kind === 'timestamp') {
        Object.assign(attrs, {
            'format': DEFAULT_DATETIME_FORMAT,
            'minute-step': 10,
            'show-second': false,
            'tip': control.tooltip || config.$i18n.t('generic_form.types.timestamp'),
            'type': 'datetime',
            'value-type': 'X'
        });

        if (typeof config.value === 'number' && config.value > 9999999999) {
            attrs.valueType = 'x';
        }
    }

    if (control.kind === 'formatted-datetime') {
        attrs['value-type'] = 'format';
    }

    Object.assign(attrs, control.kind_options || {});

    if (control.kind === 'editorial') {
        attrs.event = config.event;
        attrs.model = config.parentModel;

        delete attrs.legacy;
    }

    if (control.kind === 'number') {
        attrs.type = 'number';
    }

    if (control.kind === 'colour') {
        attrs.context = config.context;
    }

    if (control.kind === 'video-call') {
        attrs.session = config.parentModel;
        attrs.settings = config.settings;
        attrs.event = config.event;
    }

    if (['list', 'nested-object'].includes(control.kind)) {
        attrs.event = config.event;
    }

    if (control.kind === 'external') {
        attrs.options = [];
        attrs.fpType = control.kind_options.type;
        attrs.eventId = config.event?._id;

        if (control.kind_options.showCreateButton) {
            attrs.showCreateButton = true;
        }
    }

    if (control.kind === 'targets-exceptions') {
        attrs.eventId = config.event?._id;
    }

    if (['custom', 'content-page'].includes(control.kind)) {
        attrs.model = config.parentModel;
        attrs.event = config.event;
    }

    if (control.kind === 'display-only') {
        attrs.disabled = true;
    }
}

/**
 * Decorates a control with control options attributes.
 *
 * @param {FieldDescriptor} control the control object to decorate.
 * @param {ControlConfig} config the configuration object.
 * @param {object} attrs the attributes object to decorate.
 */
function decorateWithControlOptionsAttributes(control, config, attrs) {
    const options = control.kind_options;
    if (!options) return;

    const { values, values_order } = options;

    if (values) {
        attrs.options = Object.keys(values).map(v => ({ label: values[v], value: v }));
        attrs.trackBy = 'value';

    } else if (options.fields) {
        attrs.fields = options.fields;

    } else {
        attrs.options = options;
    }

    if (options.list_style === 'select') {
        attrs.trackBy = 'value';
    }

    if (options.format) {
        attrs.format = options.format;
    }

    if ([options.type, control.kind].includes('targets-exceptions')) {
        attrs.expandable = false;
    }

    if (options.id_field) {
        attrs.emitKey = options.id_field;
        attrs.valueKey = ['fp_asset', '_id'].includes(options.id_field) ? 'ids' : `${options.id_field}s`;
    }

    if (values_order) {
        attrs.options.sort((a, b) => values_order[a.value] - values_order[b.value]);
    }

    if (control.kind === 'text-multiline') {
        attrs = { ...attrs, ...options };
        delete attrs.options;
    }

    if (control.kind === 'timestamp') {
        options.timezone = config.event.timezone;
        if (options.timeInterval) {
            const interval = get(config.settings, options.timeInterval.replace(/^settings\./, ''));
            if (interval) {
                attrs.minuteStep = interval / 60;
            }
        }
    }

    if (control.kind === 'list') {
        attrs.itemType = options.item_kind;
    }

    if (control.kind === 'external') {
        attrs.multiple = !options.single_doc;
    }
}

/**
 * Applies overwrites for control attributes
 *
 * @param {FieldDescriptor} control the control object to decorate.
 * @param {Partial<ControlConfig>} overwrites the configuration overwirtes object.
 * @param {object} attrs the attributes object to decorate.
 */
function applyOwerwrites(control, overwrites, attrs) {
    if (control.kind === 'display-only') {
        overwrites.disabled = true;
    }

    if (attrs.dependant) {
        overwrites.dependant = true;
    }

    Object.assign(attrs, overwrites);
}

/**
 * Cleans the attributes object from nil values.
 *
 * @param {object} attrs the attributes object to remove nil values from
 */
function removeNilValues(attrs) {
    Object.keys(attrs).forEach(key => {
        if (isNil(attrs[key])) {
            delete attrs[key];
        }
    });
}

function cleanUpAttributes(attrs) {
    if (attrs.multiple) {
        delete attrs.single_doc;
    }
}


/**
 * Retrieves the validators for a given control.
 *
 * @param {FieldDescriptor} control - The control object.
 * @param {Object} [dependencies] - The validator's dependencies object.
 *
 * @returns {string} - The validators for the control.
 */
export function getControlValidators(control, dependencies) {
    if (control.kind === 'colour') {
        control.validations = control.validations || {};
        control.validations['hex_color'] = true;
    }

    if (control.kind === 'custom') {
        const validators = {};
        for (const validator of Object.keys(control.validations || {})) {
            validators[validator] = dependencies || true;
        }
        return Object.keys(validators).length ? validators : control.validations || '';
    }

    if (control.kind === 'external' && control.kind_options?.type === 'any') {
        control.validations = control.validations || {};
        control.validations['foreign_reference'] = true;
    }

    return Object.keys(control.validations || {}).reduce((validators, validator) => {
        if (['pattern', 'unique'].includes(validator)) return validators;
        if (validators.length) validators += '|';
        if (validator === 'required') {
            if (control.validations[validator]) {
                validators += validator;
            }
        } else if (control.kind === 'number' && (validator === 'max' || validator === 'min')) {
            validators += `${validator}_value:${control.validations[validator]}`;
        } else if (control.kind === 'text' && validator === 'maxlength') {
            validators += `max:${control.validations[validator]}`;
        } else {
            validators += `${validator}:${control.validations[validator]}`;
        }
        return validators;
    }, '');
}

/**
 * Returns a document of type fp_type where all values are the default values.
 *
 * @param {FieldDescriptor} fields the fields to extract the defaults from
 *
 * @return {Object} the document with the default values
 */
export function extractDefaults(fields, $services) {
    const defaults = {};

    for (const [key, value] of Object.entries(fields)) {
        if (!value) {
            continue;
        }

        const field = value.field || key;

        if (value.kind === 'custom') {
            defaults[field] = value.hasOwnProperty('default') ? value.default : [];
            continue;
        }

        if (value.kind === 'choice' && value.kind_options?.list_style === 'multi-choice') {
            defaults[field] = value.default;
            continue;
        }

        if (
            value.kind !== 'nested-object' ||
            !isEmpty(value.default) ||
            !value.kind_options?.fields
        ) {
            defaults[field] = clone(value.default);
            continue;
        }

        const optionsFields = value.kind_options.fields;

        if (optionsFields) {
            const nestedDefaults = extractDefaults(optionsFields, $services);
            defaults[field] = nestedDefaults;
        }
    }

    const processedDefaults = {};

    for (const [field, defaultValue] of Object.entries(defaults)) {
        const processedValue = replacePlaceholdersInTree(defaultValue, $services);
        processedDefaults[field] = processedValue;
    }

    return processedDefaults;
}

/**
 * Replaces placeholders in a tree-like structure with generated values.
 *
 * @param {any} val - The value to process.
 * @param {Services} $services - The services object.
 *
 * @returns {any} - The processed value with placeholders replaced.
 */
export function replacePlaceholdersInTree(val, $services) {
    if (!val) {
        return val;
    }

    const genRegEx = new RegExp(GENERATED_DEFAULT_VALUE_PLACEHOLDER, 'g');

    if (genRegEx.exec(val)) {
        return val.replace(genRegEx, $services.documents.generateAutoGeneratedValueString());
    }

    // handle object recursively
    if (isObject(val) && !Array.isArray(val)) {
        Object.entries(val).forEach((value, key) => val[key] = replacePlaceholdersInTree(value));
    }

    return val;
}

/**
 * Parses a server error from a string.
 *
 * @param {import('axios').AxiosError} error - The Axios error string to parse.
 *
 * @returns {ParsedServerError} - The formatted error object.
 */
export function parseServerError(error) {
    const errorString = get(error, 'data.error');
    const genericError = { detail: 'generic_form.messages.save_failed' };

    if (typeof errorString !== 'string') {
        console.warn('[utils/controls] Server did not returned an error string:', errorString);
        return genericError;
    }

    try {
        const parsedError = JSON.parse(errorString);
        if (parsedError.stack) {
            const stack = parsedError.stack.replaceAll('.appscript.vm', '').replaceAll(/:(\d+):(\d+)/g, ' at line $1:$2');
            console.error('Server responded with an error:', stack);
        } else {
            console.debug('[utils/controls] Parsed server error', parsedError);
        }

        return genericError;
    } catch (e) { /* Not a JSON string, continue */ }

    try {
        const [idField, id, message] = errorString.split(':').map(s => s.trim());
        const field = message?.substring(0, message.indexOf(' '));
        const detail = message?.substring(message.indexOf(' ') + 1);
        const parsedError = { idField, id, field, detail };

        if (!parsedError.detail) {
            return genericError;
        }

        console.debug('[utils/controls] Parsed server error', errorString, parsedError);
        return parsedError;

    } catch (e) {
        console.warn('[utils/controls] Failed to parse server error', errorString, e);
        return { detail: errorString };
    }
}

/**
 * @param {object} args
 * @param {FieldDescriptor[]} args.fields a list of field descriptors
 * @param {Record<string,unknown>} args.settings
 * @param {string} args.defaultKey
 * @param {boolean} [args.userIsInvitedByLink]
 * @param {boolean} [args.editing] whether the fields will be used for editing an existing doc or not

 * @returns {[Record<string,FieldDescriptor[]>, Record<string,FieldDescriptor[]>]}
 */
export function sortFieldsByColumnAndCategory({ fields, settings, defaultKey, userIsInvitedByLink, editing }) {
    /** @type {(field: FieldDescriptor) => boolean} */
    const shouldBeVisible = (field) => {
        if (field.hidden || userIsInvitedByLink && field.hideForInvited) {
            return false;
        }
        const settingsCond = field.conditions?.settings;
        if (Array.isArray(settingsCond)) {
            let visible = false;
            for (const { path, value } of settingsCond) {
                visible = get(settings, path) === value;
                if (!visible) {
                    break;
                }
            }
            return visible;
        }
        return true;
    };

    const visibleFields = fields.filter(field => shouldBeVisible(field));
    const fieldsLeft = fieldsByPlacement(visibleFields, 'left');
    const fieldsRight = fieldsByPlacement(visibleFields, 'right');
    return [
        sortAndGroupFields(fieldsLeft, defaultKey, editing),
        sortAndGroupFields(fieldsRight, defaultKey, editing),
    ];
}
